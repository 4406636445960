@import "./variables";
@import "./mediaquery";

.data {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 1em;
  position: relative;

  .topbar{
    border-radius: 5px 5px 0 0;
    background-color: white;
    box-shadow: 1px 1px 5px #60606030;
    margin-bottom: 1px;

    h4 {
      padding: 10px 1em;
      font-weight: 520;
      color: $blue;
    }
  }

  

  .list {
    background-color: white;
    border-radius: 0 0 8px 8px;
    // padding: 1em;
    min-height: 100%;
    height: fit-content;

    .search{
      position: relative;
      text-align: right;
      padding: 5px;

      select{
        background: white;
        border: 1px solid rgba(96, 96, 96, 0.3137254902);
        border-radius: 5px;
        padding: 8px;
        font-size: small;
        width: 190px;
        margin-right: 10px;
      }
  
      input{
        width: 25%;
        padding: 8px;
        outline: none;
        border: 1px solid rgba(96, 96, 96, 0.3137254902);
        font-size: small;
        border-radius: 5px;
      }
      .fa-search{
        position: absolute;
        top: 12px;
        right: 10px;
        bottom: 0;
        color: rgba(96, 96, 96, 0.3137254902);
      }
    }

    h5 {
      font-size: medium;
      color: $primary;
      margin-bottom: 5px;
    }

    h6 {
      font-size: medium;
      color: $accent;
      margin: 5px;
      text-align: center;
      border: 1px solid $accent;
      border-radius: 2px;
      font-weight: 400;
      padding: 4px;
    }

    .select {
      label {
        display: block;
        margin: 10px 0 10px 0;
        font-size: medium;
      }

      select {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      select:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .input {
      label {
        display: block;
        margin: 10px 0 10px 0;
        font-size: medium;
      }

      input {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }
      textarea {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      textarea:focus {
        border-bottom: 2px solid $primary;
      }
    }
    button {
      display: block;
      margin: 1em auto 1em auto;
      padding: 12px;
      width: 80%;
      background-color: $primary;
      color: white;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid #60606070;
      border-top: 2px solid #60606070;
      cursor: pointer;
    }
    button:hover {
      background-color: rgba($color: $primary, $alpha: 0.8);
    }
    h3 {
      padding: 10px 0 10px 0;
      font-size: medium;
    }

    hr {
      margin-bottom: 10px;
    }

    .item{
      border: 1px solid rgba(96,96,96,.063);
      border-radius: 5px;
      box-shadow: 1px 1px 5px rgba(96,96,96,.063);
      cursor: pointer;
      margin-bottom: 5px;
      padding: 5px;
      transition: box-shadow .5s ease-in-out;
      .row{
        gap: 1em;
      }
      div{
        h5{
          font-size: medium;
          color: $dark;
          font-weight: 400;
        }
        p{
          color: grey;
          font-size: small;
          font-weight: 500;
        }
      }
    }

    .head,
    .row {
      display: grid;
      grid-template-columns: 1fr 0.3fr 1fr 0.5fr 1fr 1fr;
      gap: 1px;
      align-items: center;

      div{
        h5{
          font-size: medium;
          color: $dark;
          font-weight: 400;
        }
        p{
          color: grey;
          font-size: small;
          font-weight: 500;
        }
      }
      
      @include maxwidth(mobile) {
        grid-template-columns: 1fr 1fr !important;

        :nth-child(3),
        :nth-child(4),
        :nth-child(5) {
          display: none;
        }
      }
      @include maxwidth(tablet) {
        grid-template-columns: 1fr 1fr 0.5fr !important;

        :nth-child(4),
        :nth-child(5) {
          display: none;
        }
      }
      .green{
        background: rgba($color: #1AAE9F, $alpha: 0.3);
        color: #1AAE9F;
        padding: 3px 12px;
        border-radius: 20px;
        font-size: small;
      }
      .yellow{
        background: rgba($color: $accent, $alpha: 0.3);
        color: $accent;
        padding: 3px 12px;
        border-radius: 20px;
        font-size: small;
      }
      .red{
        background: rgba($color: #BD0909, $alpha: 0.3);
        color: #BD0909;
        padding: 3px 12px;
        border-radius: 20px;
        font-size: small;
      }
      .purple{
        background: rgba($color: #7070F1, $alpha: 0.3);
        color: #7070F1;
        padding: 3px 12px;
        border-radius: 20px;
        font-size: small;
      }
    }

    .row {
      cursor: pointer;
      margin-bottom: 2px;
    }
    .row:hover {
      box-shadow: 1px 1px 5px #60606020;
    }

    h4 {
      font-size: medium;
      color: $primary;
      background-color: #60606020;
      border-radius: 2px;
      padding: 5px;
    }
    p {
      font-size: medium;
      border-radius: 2px;
      padding: 7px 5px 7px 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    z-index: 999999;
    animation: zoom 1s ease;

    .container {
      background-color: white;
      border-radius: 8px;
      padding: 1em 2em 1em 2em;
      margin: 0 auto 0 auto;
      color: #000000;
      max-width: 85vw;
      max-height: 70vh;
      height: fit-content;
      overflow-y: auto;
      width: 100%;
      position: relative;

      h6{
        color: $dark;
        border: none;
        font-size: medium;
        font-weight: 400;
        padding: 5px;
        text-align: right;
        cursor: pointer;
      }
      h6:hover{
        color: $accent;
      }

      .content {
        position: relative;
        min-height: 30vh;

        p {
          font-size: small;
          margin: 0px 0 0px 0;
          word-break: break-all !important;

          b {
            color: #74465c;
            cursor: pointer;
          }
        }

        .tally {
          position: absolute;
          top: 5px;
          right: 0;

          .active {
            background-color: #74465c;
          }

          p {
            background-color: $accent;
            border-radius: 2px;
            cursor: pointer;
            width: 32px;
            height: 32px;
            text-align: center;
            color: white;
          }
          p:hover {
            background-color: #74465c;
          }
        }
      }

      h3 {
        text-align: center;
        font-size: large !important;
        color: $primary;
      }

      .fa-times {
        position: absolute;
        right: 1em;
        top: 10px;
        padding: 10px;
        cursor: pointer;
        color: $primary;
      }
      .fa-times:hover {
        color: $accent;
      }

      hr {
        margin-bottom: 10px;
      }

      i {
        display: block;
        width: fit-content;
        padding: 5px;
        margin: 0 0 0 auto;
        color: $accent;
      }
      i:hover {
        color: $primary;
      }

      h3 {
        padding: 0px 0 10px 0;
        font-size: medium;
      }

      form {
        textarea {
          resize: none;
          width: 100%;
          margin: auto;
          padding: 4px;
          font-size: 18px;
        }
      }

      .input-map {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 10px 0 10px 0;
          font-weight: 400;
        }
        textarea {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        textarea:focus {
          border-bottom: 2px solid $primary;
        }
        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
      .usrselect {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 10px 0 10px 0;
          font-weight: 400;
        }

        select {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      button {
        display: block;
        margin: 1em auto 1em auto;
        padding: 12px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid #60606070;
        border-top: 2px solid #60606070;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }
    }
    @include maxwidth(mobile) {
      .container {
        max-width: 85% !important;
      }
    }
    @include maxwidth(tablet) {
      .container {
        max-width: 85% !important;
      }
    }

    .dets {
      display: grid;
      grid-template-columns: 1fr auto;

      .fa {
        color: $accent;
        height: fit-content;
        margin: auto;
        padding: 5px;
      }
      .fa:hover {
        color: $primary;
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }

    hr {
      margin-bottom: 10px;
    }
  }

  .editdata {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.3);

    .cont {
      background-color: white;
      border: 1px solid #60606030;
      box-shadow: 2px 2px 10px #60606030;
      border-radius: 8px;
      padding: 1em;
      max-width: 80%;
      width: 100%;
      margin: auto;
      height: fit-content;
      max-height: 80vh;
      overflow: auto;
    }

    .dets {
      display: grid;
      grid-template-columns: 1fr auto;

      .fa {
        color: $accent;
        height: fit-content;
        margin: auto;
        padding: 5px;
      }
      .fa:hover {
        color: $primary;
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }

    hr {
      margin-bottom: 10px;
    }
  }
}

@keyframes zoom {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}
