@import "./variables";
@import "./mediaquery";

.news {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 1em 0;
  position: relative;

  p {
    font-size: medium !important;
  }

  .list {
    background-color: white;
    border-radius: 8px;
    padding: 1em;
    min-height: 100%;
    height: fit-content;
    width: 100%;

    .utp {
      display: grid;
      grid-template-columns: 1fr 1fr auto auto;
      gap: 1em;

      .search {
        position: relative;

        .fa-search {
          position: absolute;
          top: 10px;
          right: 5px;
          bottom: 0;
          color: $gray;
        }

        input {
          width: 100%;
          padding: 8px;
          outline: none;
          border: 1px solid $gray;
          font-size: small;
          border-radius: 5px;
        }
      }

      h3 {
        color: $secondary;
        font-size: small;
        font-weight: 520;
        display: flex;
        align-items: center;
      }

      p {
        font-size: small;
        color: $secondary;
        background-color: #feece2;
        border-radius: 5px;
        border: 1px solid #feece2;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 10px;

        .fa-sort {
          margin-right: 5px;
        }
        .fa-toggle {
          margin-right: 5px;
        }
      }
      p:hover {
        color: $secondary;
        background-color: $accent;
      }
    }

    h3 {
      padding: 10px 0 10px 0;
      font-size: medium;
    }

    hr {
      margin-bottom: 10px;
    }

    .newsBox {
      margin: 10px 0 10px 0;
      box-shadow: 2px 2px 5px #60606010;
      border: 1px solid #60606010;
      border-radius: 5px;
      padding: 1em;
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 1em;
      cursor: pointer;

      img {
        max-width: 250px;
        width: 100%;
        max-height: 200px;
        height: 100%;
        object-fit: cover;
      }

      h4 {
        font-size: medium;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }

    .griddiv {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 1em;
      gap: 1em;
      .newsgrid {
        margin: 10px 0 10px 0;
        box-shadow: 2px 2px 5px #60606010;
        border: 1px solid #60606010;
        border-radius: 5px;

        cursor: pointer;

        img {
          max-width: 250px;
          width: 100%;
          max-height: 200px;
          height: 100%;
          object-fit: cover;
        }

        h4 {
          font-size: medium;
          font-weight: 500;
          margin-bottom: 10px;
        }
      }
    }

    .dropoptions {
      position: absolute;
      top: 70px;
      right: 50px;
      .optbox {
        background-color: $white;
        height: fit-content;
        border: 1px solid rgba(212, 210, 210, 0.5);
        border-radius: 8px;
        padding: 0.1em;
        p {
          padding: 0.2em;
          color: gray;
        }
        p:hover {
        color: $secondary;
        background-color: $active;
      }
      }
    }

    .new {
      h6 {
        text-align: center !important;
        display: block !important;
        width: 100%;
      }

      form {
        width: 100% !important;
      }
      .div2equal {
        width: 100%;
      }

      button {
        display: block;
        margin: 1em auto 1em auto;
        padding: 12px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid #60606070;
        border-top: 2px solid #60606070;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }
      .input {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 10px 0 10px 0;
          font-weight: 400;
          width: 100%;
          text-align: left;
        }

        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606005;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }

        textarea {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606005;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        textarea:focus {
          border-bottom: 2px solid $primary;
        }
      }
      .select {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 10px 0 10px 0;
          font-weight: 400;
          width: 100%;
          text-align: left;
        }

        select {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606005;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }

  //   h6 {
  //     font-size: small;
  //     height: 24px;
  //     line-height: 24px;
  //     width: fit-content;
  //     display: block;
  //     text-align: right;
  //     color: $accent;
  //     font-weight: 400;
  //     margin: 10px 1em 0 auto;
  //   }
  //   form {
  //     width: 75%;
  //     text-align: center;
  //     margin: 0 auto;
  //   }
  //   .newsinput {
  //     padding: 10px 0 10px 0;
  //     label {
  //       text-align: left;
  //       cursor: pointer;
  //       input {
  //         width: 100%;
  //         background-color: transparent;
  //         border-radius: 8px;
  //         font-family: "Poppins";
  //         font-style: normal;
  //         font-weight: 400;
  //         font-size: medium;
  //         padding: 5px 0 5px 10px;
  //         // text-align: center;
  //         color: black;
  //         border: 1px solid #b6b6b6;
  //         cursor: pointer;
  //         display: block;
  //         margin: 10px auto 0 auto;
  //       }
  //     }
  //     .input {
  //       width: 100%;
  //       background-color: transparent;
  //       border-radius: 8px;
  //       font-family: "Poppins";
  //       font-style: normal;
  //       font-weight: 400;
  //       font-size: medium;
  //       padding: 5px 0 5px 10px;
  //       // padding: 5px;
  //       // text-align: center;
  //       color: black;
  //       border: 1px solid #b6b6b6;
  //       cursor: pointer;
  //       display: block;
  //       margin: 10px auto 0 auto;
  //     }
  //     .textarea {
  //       height: 12vh;
  //     }
  //     h4 {
  //       font-family: "Poppins";
  //       font-style: normal;
  //       font-size: medium;
  //       color: #000000;
  //       font-weight: 400;
  //       text-align: left;
  //       span {
  //         color: red;
  //       }
  //     }
  //   }

  //   .newsselect {
  //     padding: 10px 0 10px 0;
  //     select {
  //       width: 100%;
  //       background-color: transparent;
  //       border-radius: 8px;
  //       font-family: "Poppins";
  //       font-style: normal;
  //       font-weight: 400;
  //       font-size: medium;
  //       padding: 5px 0 5px 10px;
  //       text-align: left;
  //       color: black;
  //       border: 1px solid #b6b6b6;
  //       cursor: pointer;
  //       display: block;
  //       margin: 10px auto 0 auto;
  //     }
  //     h4 {
  //       font-family: "Poppins";
  //       font-style: normal;
  //       font-weight: 400;
  //       font-size: medium;
  //       line-height: 14px;
  //       color: #000000;
  //       text-align: left;
  //       span {
  //         color: red;
  //       }
  //     }
  //   }

  //   .top {
  //     padding: 2em 2em 0 2em;
  //     h3 {
  //       color: $primary;
  //       margin-bottom: 1em;
  //       text-align: center;
  //     }
  //     p {
  //       text-align: center;
  //     }

  //     .search {
  //       background-color: white;
  //       height: 44px;
  //       border-bottom: 1px solid $line;
  //       border-top: 1px solid $line;

  //       .cont {
  //         line-height: 44px;
  //         width: fit-content;
  //         margin: 0 2em 0 auto;

  //         span {
  //           color: $accent;
  //         }
  //       }
  //     }
  //     .bar {
  //       padding: 0 1em 0 1em;

  //       .container {
  //         display: grid;
  //         grid-template-columns: repeat(4, auto);
  //         width: fit-content;

  //         .item {
  //           border-top: 1px solid $line;
  //           border-left: 1px solid $line;
  //           border-right: 1px solid $line;
  //           border-bottom: 1px solid transparent;
  //           margin-bottom: -1px;
  //           padding: 5px 1em 5px 1em;
  //           font-weight: 400;
  //           font-size: medium;
  //           cursor: pointer;
  //         }

  //         .active {
  //           border-top: 1px solid $line;
  //           border-left: 1px solid $line;
  //           border-right: 1px solid $line;
  //           border-bottom: 1px solid white;
  //           background-color: white;
  //           padding: 5px 1em 5px 1em;
  //           font-weight: 400;
  //           font-size: medium;
  //           margin-bottom: -1px;
  //           cursor: pointer;
  //         }
  //       }
  //     }
  //     @include maxwidth(mobile) {
  //       * {
  //         font-size: small !important;
  //       }
  //     }
  //     @include maxwidth(tablet) {
  //       * {
  //         font-size: small !important;
  //       }
  //     }
  //   }

  //   .newsStats {
  //     margin: 2em;
  //     display: grid;
  //     grid-template-columns: repeat(3, 1fr);
  //     gap: 3em;

  //     .newsBox {
  //       box-shadow: 2px 1px 5px #60606030;
  //       background: #ffffff;
  //       border-radius: 8px;
  //       text-align: center;
  //       height: 350px;
  //       transition: 0.8s;

  //       img {
  //         width: 100%;
  //         height: 50%;
  //         display: block;
  //       }

  //       h4 {
  //         font-family: "Poppins";
  //         font-style: normal;
  //         font-weight: 800;
  //         font-size: large;
  //         line-height: 29px;
  //         color: #74465c;
  //         margin: 10px 0;
  //         padding: 0 5px;
  //       }

  //       p {
  //         font-family: "Poppins";
  //         font-style: normal;
  //         font-weight: 800;
  //         font-size: medium;
  //         line-height: 44px;
  //         text-align: center;
  //         color: #000000;
  //         background: #ffffff;
  //       }

  //       h6 {
  //         font-family: "Poppins";
  //         font-style: italic;
  //         font-weight: 300;
  //         font-size: 14px;
  //         line-height: 17px;
  //         text-align: right;
  //         color: #000000;
  //         margin-top: 20px;
  //       }
  //     }

  //     .newsBox:hover {
  //       transform: scale(1.04);
  //     }

  //     @include maxwidth(mobile) {
  //       display: flex !important;
  //       flex-direction: column;
  //     }
  //   }

  //   .bot {
  //     display: grid;
  //     grid-template-columns: auto 1fr auto;
  //     height: 100%;
  //     position: relative;
  //     margin: 0 2em 2em 2em;
  //     gap: 1em;

  //     .left {
  //       width: fit-content;
  //       position: relative;
  //       padding: 1em 1em 1em 1em;
  //       background-color: white;
  //       border: 1px solid $line;
  //       min-width: 250px;
  //       border-radius: 5px;
  //       box-shadow: 1px 1px 5px #60606010;

  //       h5 {
  //         margin: 1em 0 1em 0;
  //         font-size: medium;
  //         cursor: pointer;
  //       }

  //       h5:hover {
  //         color: $accent;
  //       }

  //       .active {
  //         color: $accent;
  //       }
  //     }

  //     .main {
  //       height: 100%;
  //       overflow-y: auto;
  //       padding-bottom: 1em;

  //       .head {
  //         display: grid;
  //         grid-template-columns: 32px 1fr 1.4fr 0.7fr 0.5fr 1fr 1fr 0.5fr;
  //         gap: 1px;
  //         h4 {
  //           background-color: #eef2f5;
  //           padding: 5px;
  //           overflow: hidden;
  //           font-size: small;
  //         }
  //       }
  //       .news {
  //         display: grid;
  //         grid-template-columns: 32px 1fr 1.4fr 0.7fr 0.5fr 1fr 1fr 0.5fr;
  //         gap: 1px;
  //         background-color: white;
  //         box-shadow: 1px 1px 5px #60606030;
  //         margin-bottom: 1px;
  //         cursor: pointer;

  //         h4 {
  //           font-weight: 400;
  //           padding: 5px 5px 5px 5px;
  //           font-size: small;
  //         }
  //       }
  //       .news:hover {
  //         background-color: #eef2f5;
  //       }
  //       .dthead {
  //         font-weight: bold;
  //         display: grid;
  //         grid-template-columns: 32px 0.7fr 0.5fr 0.5fr 0.8fr 0.8fr;
  //         gap: 1px;
  //         h4 {
  //           background-color: #eef2f5;
  //           padding: 5px 1em 5px 1em;
  //           font-weight: 400;
  //         }
  //       }
  //       .dt {
  //         display: grid;
  //         grid-template-columns: 32px 0.7fr 0.5fr 0.5fr 0.8fr 0.8fr;
  //         gap: 1px;
  //         background-color: white;
  //         box-shadow: 1px 1px 5px #60606030;
  //         margin-bottom: 1px;
  //         cursor: pointer;

  //         h4 {
  //           font-weight: 400;
  //           padding: 5px 5px 5px 5px;
  //           font-size: small;
  //         }
  //       }
  //       .dt:hover {
  //         background-color: #eef2f5;
  //       }

  //       .ld {
  //         position: relative;
  //         height: 40vh;
  //       }
  //     }

  //     .right {
  //       width: 300px;
  //       position: relative;
  //       padding: 10px 1em 10px 1em;
  //       background-color: white;
  //       border: 1px solid $line;

  //       h5 {
  //         display: block;
  //         margin: 1em 1em 1em auto;
  //         color: $accenter;
  //         cursor: pointer;
  //         width: fit-content;
  //       }
  //       h5:hover {
  //         color: $accent;
  //       }
  //     }
  //     @include maxwidth(mobile) {
  //       grid-template-columns: 1fr !important;

  //       * {
  //         font-size: x-small !important;
  //       }

  //       .right,
  //       .left {
  //         display: none !important;
  //       }

  //       .main {
  //         margin: 0 1em 1em 1em;
  //       }
  //       .head {
  //         grid-template-columns: 16px 1fr 1fr 0.5fr 0.5fr !important;
  //         :nth-child(4) {
  //           display: none !important;
  //         }
  //         :nth-child(6) {
  //           display: none !important;
  //         }
  //         :nth-child(7) {
  //           display: none !important;
  //         }
  //       }

  //       .news {
  //         grid-template-columns: 16px 1fr 1fr 0.5fr 0.5fr !important;
  //         :nth-child(4) {
  //           display: none !important;
  //         }
  //         :nth-child(6) {
  //           display: none !important;
  //         }
  //         :nth-child(7) {
  //           display: none !important;
  //         }
  //       }
  //     }

  //     @include maxwidth(tablet) {
  //       grid-template-columns: 1fr !important;

  //       * {
  //         font-size: x-small !important;
  //       }

  //       .right,
  //       .left {
  //         display: none !important;
  //       }

  //       .main {
  //         margin: 0 1em 1em 1em;
  //       }
  //     }
  //   }

  //   .activity {
  //     box-sizing: border-box;
  //     background: #f6fafd;
  //     border: 1px solid hsl(228, 12%, 92%);
  //     border-radius: 10px;
  //     margin: 0 0 10px 0;

  //     h4 {
  //       box-sizing: border-box;
  //       background: #edf2f5;
  //       border: 1px solid #efeff2;
  //       border-radius: 10px 10px 0px 0px;
  //       font-size: x-small;
  //       padding: 6px;
  //     }

  //     .div2equal {
  //       grid-template-columns: auto 1fr;
  //       :last-child {
  //         text-align: right;
  //       }
  //     }
  //     p {
  //       padding: 6px;
  //       font-size: xx-small;
  //     }
  //   }

  //   .newspopup {
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     display: flex;
  //     align-items: center;

  //     .container {
  //       width: fit-content;
  //       background-color: white;
  //       border-radius: 8px;
  //       margin: auto;
  //       box-shadow: 1px 1px 5px #60606010;
  //       padding: 1em;
  //       position: relative;

  //       .top {
  //         display: grid;
  //         grid-template-columns: 1fr auto auto;
  //         gap: 10px;
  //       }

  //       h6 {
  //         color: $accent;
  //         font-size: smaller;
  //       }

  //       p {
  //         font-size: medium;
  //         padding: 10px 0 10px 0;
  //       }

  //       span {
  //         font-weight: bolder;
  //       }

  //       i {
  //         cursor: pointer;
  //       }
  //       i:hover {
  //         color: $accent;
  //       }

  //       button {
  //         display: block;
  //         width: 70%;
  //         margin: 1em auto 1em auto;
  //         cursor: pointer;
  //         padding: 10px;
  //         border-radius: 12px;
  //         color: white;
  //       }

  //       button:hover {
  //         background-color: rgba($color: #000000, $alpha: 0) !important;
  //         color: black;
  //       }

  //       .buttons {
  //         :first-child {
  //           background-color: orange;
  //           border: 1px solid orange;
  //         }
  //         :last-child {
  //           background-color: red;
  //           border: 1px solid red;
  //         }
  //       }
  //     }
  //   }

  //   .dtpopup {
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     display: flex;
  //     align-items: center;

  //     .container {
  //       width: fit-content;
  //       background-color: white;
  //       border-radius: 8px;
  //       margin: auto;
  //       box-shadow: 1px 1px 5px #60606010;
  //       padding: 1em;
  //       position: relative;

  //       .top {
  //         display: grid;
  //         grid-template-columns: 1fr auto auto;
  //         gap: 10px;
  //       }

  //       h6 {
  //         color: $accent;
  //         font-size: smaller;
  //       }

  //       p {
  //         font-size: medium;
  //         padding: 10px 0 10px 0;
  //       }

  //       span {
  //         font-weight: bolder;
  //       }

  //       i {
  //         cursor: pointer;
  //       }
  //       i:hover {
  //         color: $accent;
  //       }

  //       button {
  //         display: block;
  //         width: 70%;
  //         margin: 1em auto 1em auto;
  //         cursor: pointer;
  //         padding: 10px;
  //         border-radius: 12px;
  //         color: white;
  //       }

  //       button:hover {
  //         background-color: rgba($color: #000000, $alpha: 0) !important;
  //         color: black;
  //       }

  //       .buttons {
  //         :first-child {
  //           background-color: orange;
  //           border: 1px solid orange;
  //         }
  //         :last-child {
  //           background-color: red;
  //           border: 1px solid red;
  //         }
  //       }
  //     }
  //   }
  // }
}
