$primary: #29b6f6;
$primarylight: #028e37;
$primarylighter: #02bc6b;
$secondary: #2196f3;
$accent: #ffa726;
$bg: #f6fafd;
$bg1: #f6fafd;
$line: #e4e8eb;
$dark: #061e06;
$blue: #000a74;
$white: #ffffff;
$red: #dc143c;
$active: #feece2;
$gray: #60606050;
